// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'


export const wizardSlice = createSlice({
  name: 'wizard',
  initialState: {
    step1: true,
    step2: false,
    step3: false,
    verifyEmailRoute: false,
  },
  reducers: {
    step1: (state, action) => {
        state.step1 = action.payload
    },
    step2: (state, action) => {
        state.step2 = action.payload
    },
    step3: (state, action) => {
        state.step3 = action.payload
    },
    handleVerifyEmailRoute: (state, action) => {
      state.verifyEmailRoute = action.payload
    }
}
})

export const { step1, step2, step3, handleVerifyEmailRoute } = wizardSlice.actions

export default wizardSlice.reducer
